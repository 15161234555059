import React from 'react';
import banner from '../../images/resized_banner.jpg'
import HeaderImage from '../private/headerImage';
const Banner = () => {
    return (
        <div className='banner'>
            <img src={banner} alt="" />
            <HeaderImage className='special'>
                Sanktuarium Maryjne<br/>w Starych Skoszewach
            </HeaderImage>
        </div>
    );
}

export default Banner;
