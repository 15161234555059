import * as React from "react"
import Banner from "../components/public/banner"
import Layout from "../components/public/layout"
import Annoucementspanel from "../components/public/annoucementsPanel"
import News from "../components/public/news"
import Media from "../components/public/media"
import Live from "../components/public/live"
import { Helmet } from "react-helmet"
import '../styles/main.css'

const IndexPage = () => {
    return (
        <Layout>
            <Helmet title={`Parafia w Starych Skoszewach`} />
            <div className="container">
                <div className="left-col">
                    <Banner />
                    <News />
                </div>
                <div className="right-col">
                    <Annoucementspanel />
                    <Live embedId="laAihQWUzx4"/>
                </div>
            </div>
            <Media />
        </Layout>
    )
}

export default IndexPage
