import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import default_photo from '../../images/default_photo.png'
import HeaderImage from '../private/headerImage';
const News = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulArtykulyBiezace(
                sort: {
                    fields: publishDate,
                    order: DESC
                }
            ){
                edges{
                    node {
                        mainPhoto {
                        file {
                                url
                            }
                        }
                        title
                        linkName
                    }
                }
            }
        }
    `)
    return (
        <div className="news">
            <h2 style={{ lineHeight: '80px' }}>Najnowsze</h2>
            {
                data.allContentfulArtykulyBiezace.edges.slice(0, 6).map(edge => {
                    return (
                        <div className="element">
                            <Link to={`artykuly/${edge.node.linkName}`} className='news-link'>
                                <HeaderImage className='normal'>
                                    {edge.node.title}
                                </HeaderImage>
                                <img src={edge.node.mainPhoto != null ? edge.node.mainPhoto.file.url : default_photo} alt="article" />
                            </Link>
                        </div>

                    )
                })
            }
        </div>
    );
}

export default News;
