import React from "react";
import PropTypes from "prop-types";

const Live = ({ embedId }) => (
    <>
        <div className="announcements">
        
        <h2 style={{marginBottom: 20}}>Transmisja na żywo z kościoła</h2>
        <div className="video-responsive">
            <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Transmisja na żywo"
            pla
            />
        </div>
        </div>
    </>

);

Live.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default Live;