import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Link } from 'gatsby';
const Annoucementspanel = () => {

    const data = useStaticQuery(graphql`
    query {
        allContentfulOgloszeniaIIntencje(
            sort: {
                fields: publishDate,
                order: DESC
            }
        ){
            edges {
                node {
                    title
                    publishDate(formatString: "D MMMM", locale: "pl")
                    linkName
                }
            }
        }
    }
    
    `)
    return (
        <div className="announcements">
            <h2>Ogłoszenia parafialne</h2>
            {
                data.allContentfulOgloszeniaIIntencje.edges.slice(0, 5).map(edge => {
                    return (
                        <Link to={`/ogloszenia-i-intencje/${edge.node.linkName}`}>
                            <div className="single">
                                <span className='date'>{edge.node.publishDate}</span><br />
                                <span className='title'>{edge.node.title}</span>
                            </div>
                        </Link>
                    )
                })
            }
            <span className='others'><Link to='/ogloszenia-i-intencje'>STARSZE</Link></span>
        </div>
    );
}

export default Annoucementspanel;
