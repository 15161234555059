import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faImages } from '@fortawesome/free-solid-svg-icons'
const Media = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulMultimedia(
            sort: {
                fields: publishDate,
                order: DESC
            }
        ) {
                edges {
                    node {
                        videoLink
                        mainPhoto {
                            file {
                                url
                            }
                        }
                        title
                        linkName
                    }
                }
            }
        }
    `)
    return (
        <div className="media">
            <div className="media-container">
                <h2 style={{ lineHeight: '80px', color: 'white', textShadow: '2px 2px #000000B2' }}>Multimedia</h2>
                {
                    data.allContentfulMultimedia.edges.slice(0, 3).map(edge => {
                        // const link = edge.node.videoLink.split('=').at(-1)

                        return (
                            <Link to={`multimedia/${edge.node.linkName}`}>
                                <div className="element">
                                    <img src={edge.node.mainPhoto.file.url} alt="" style={{ width: '100%', height: 320, transition: '.2s'}} />
                                    {edge.node.videoLink != null ? <FontAwesomeIcon icon={faPlay} className='media-icon' /> : <FontAwesomeIcon icon={faImages} className='media-icon' />}

                                    <h3>{edge.node.title}</h3>

                                </div>
                            </Link>

                        )
                    })
                }
            </div>
        </div>
    );
}

export default Media;

